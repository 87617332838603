<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" width="200px">
				<div style="padding: 30px 20px;">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<i class="iconfont ft14" :class="item.icon"></i>
							<span class="ft14 ml10">{{item.name}}</span>
						</div>					
				</div>
			</a-layout-sider>
			<a-layout-content style="padding: 30px 20px;height: 100vh; background: #F7F8FA;">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
	</div>
</template>

<script>
	export default {
		
		data() {
			return {
				showForm:false,
				
				menus: [
					{
						name: '合伙设置',
						icon: 'iconpartner_setting',
						key: 'partner_shezhi',
						link: '/partner'
					},
					{
						name: '股东合伙人',
						icon: 'iconpartner_shareholder',
						key: 'partner_shareholder',
						link: '/partner/shareholder'
					},
					{
						name: '自定义合伙人',
						icon: 'iconapplication_tab01',
						key: 'partner_custom',
						link: '/partner/custom'
					},
					{
						name: '员工合伙人',
						icon: 'iconpartner_staff',
						key: 'partner_employeePartner',
						link: '/partner/employeePartner'
					},
					{
						name: '会员合伙人',
						icon: 'iconpartner_client',
						key: 'partner_huiyuanPartner',
						link: '/partner/memberPartner'
					},
					{
						name: '提现记录',
						icon: 'iconpartner_cash',
						key: 'partner_withdraw',
						link: '/partner/withdraw'
					},
					{
						name: '海报库',
						icon: 'iconappoint_tab08',
						key: 'partner_poster',
						link: '/partner/poster'
					},
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
		}
	}
</script>

<style>
	
</style>
